import { CSSObject, css } from '@emotion/react';
import React, { FC } from 'react';
import Container from '../Container';
import { MonoFontLabel } from '../../common/typography';
import { SYSTEM_FONTS } from '../../../styles/typography';
import presets from '../../../styles/presets';
import Text from '../../Homepage/Text';
import { ButtonLink } from '../../common/Button';
import { markdownText } from '../../../pages/doctors';
import { ReactComponent as Bullet } from '../../../assets/svgs/homepage/bullet.svg';
import PhoneBg from '../../../assets/svgs/homepage/header-bg.svg';
import VideoOverlay from '../../VideoOverlay';
import {
  ENGLISH_STUDY_LINK,
  GERMAN_STUDY_LINK,
  NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK,
  YOUTUBE_B2C_EMBED_URL,
} from '../../../utils/constants';
import { useIntl } from 'react-intl';
import { ReactComponent as StudyBadge } from '../../../assets/svgs/homepage/img-sticker_studyresults.svg';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 'calc(3vw)',
});

export const renderSpanBulletItems = (bulletItems: string[]) => (
  <div
    css={{
      display: 'flex',
      textAlign: 'center',
      width: '100%',
      justifyContent: 'center',
      maxWidth: 600,
    }}
  >
    <Text
      css={{
        fontFamily: ['Hero New'].concat(SYSTEM_FONTS).join(', '),
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      {bulletItems.map((item) => (
        <span
          key={item}
          css={{
            marginRight: 10,

            'first-of-type': {
              marginLeft: 0,
            },
          }}
        >
          <Bullet
            css={{
              position: 'relative',
              top: 4,
              marginRight: 5,
            }}
          />
          <span>{item}</span>
        </span>
      ))}
    </Text>
  </div>
);

interface Props {
  eliCheckerLink?: string;
}

const Header: FC<Props> = ({ eliCheckerLink }) => {
  const intl = useIntl();

  const bulletItems = [
    intl.formatMessage({
      id: 'homepage.v2.feature.header.text.one',
      defaultMessage:
        'Von allen gesetzlichen und vielen privaten Krankenkassen übernommen',
    }),
    intl.formatMessage({
      id: 'homepage.v2.feature.header.text.two',
      defaultMessage: 'Wissenschaftlich fundiert',
    }),
    intl.formatMessage({
      id: 'homepage.v2.feature.header.text.three',
      defaultMessage: 'Personalisiert',
    }),
  ];

  const phoneSVGStyles: CSSObject = {
    backgroundImage: `url(${PhoneBg})`,
    transition: 'all 0.3s ease',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: 'auto',
    marginBottom: '-10vw',
    minHeight: '250px',
    marginTop: 'calc(13vw)',
    zIndex: -1,
    position: 'relative',
    maxHeight: 'calc(100vh - 200px)',
    [presets.sm]: {
      marginBottom: '-3vw',
      minHeight: '250px',
      marginTop: 'calc(3.5vw)',
    },
    [presets.md]: {
      marginBottom: '-5vw',
      minHeight: 'calc(250px + 5vw)',
    },
    [presets.lg]: {
      marginBottom: '-2vw',
      minHeight: 'calc(250px + 8vw)',
    },
    [presets.xl]: {
      marginTop: 'calc(2vw)',
      marginBottom: '-2vw',
      minHeight: 'calc(250px + 10vw)',
    },
  };

  const badgeStyles: CSSObject = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%) rotate(0deg)',
    zIndex: 0,
    top: 'calc(-2% )',
    [presets.sm]: {
      top: 'calc(-34% + 2.5vw)',
    },
    [presets.md]: {
      top: 'calc(-14% + 3vw)',
      right: 'calc(10% - 10vw)',
      left: 'auto',
      transform: 'rotate(-15deg)',
    },
    [presets.lg]: {
      right: 'calc(10% - 8vw)',
    },
    [presets.xl]: {
      right: 'calc(10% - 6vw)',
    },
  };

  return (
    <Container css={container}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <MonoFontLabel
          css={{
            border: '2px solid #489F9D',
            padding: '4px 10px',
            borderRadius: 18,
            fontWeight: 700,
            fontSize: 14,
            marginRight: 10,
          }}
        >
          {intl.formatMessage({
            id: 'homepage.v2.feature.header.prefix.one',
            defaultMessage: 'Kostenfrei',
          })}
        </MonoFontLabel>
        <MonoFontLabel
          css={{
            fontWeight: 700,
            fontSize: 14,
            marginRight: 10,
          }}
        >
          {intl.formatMessage({
            id: 'homepage.v2.feature.header.prefix.two',
            defaultMessage: 'auf Rezept',
          })}
        </MonoFontLabel>
      </div>

      <div
        css={{
          textAlign: 'center',
        }}
      >
        <Text
          css={{
            fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 28,
            fontWeight: 700,
            color: '#160B2C',
            marginBottom: 12,
            [presets.md]: { fontSize: 33 },
            [presets.xl]: { fontSize: 33 },
          }}
        >
          {markdownText(
            intl.formatMessage({
              id: 'homepage.v2.feature.header.title',
              defaultMessage: `<b style="color: #489F9D">Reizdarm-Therapie</b> als App.`,
            })
          )}
        </Text>
      </div>

      {renderSpanBulletItems(bulletItems)}

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',

          '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          },
        }}
      >
        <VideoOverlay
          buttonType="button"
          link={YOUTUBE_B2C_EMBED_URL}
          buttonText={intl.formatMessage({
            id: 'homepage.v2.feature.header.button.one',
            defaultMessage: 'So funktioniert’s',
          })}
        />
        <ButtonLink
          css={{
            margin: '5px 10px',
            backgroundColor: '#3D72DF',
            color: '#ffffff',
            fontWeight: 700,
            padding: '1rem 1rem',
          }}
          to={
            eliCheckerLink
              ? eliCheckerLink
              : NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK
          }
        >
          {intl.formatMessage({
            id: 'homepage.v2.feature.header.button.two',
            defaultMessage: 'Kostenfrei starten',
          })}
        </ButtonLink>
      </div>
      {intl.locale !== 'de' && (
        <Text style={{ fontSize: 16, marginTop: 10 }}>
          {intl.formatMessage({
            id: 'new.landingpage.disclaimer',
            defaultMessage: '*Therapy is currently only available in German.',
          })}
        </Text>
      )}
      <div
        css={{
          position: 'relative',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 'calc(8% + 5vw)',

          [presets.md]: {
            marginTop: 0,
          },
        }}
      >
        <div css={phoneSVGStyles}></div>
        <a
          href={intl.locale === 'de' ? GERMAN_STUDY_LINK : ENGLISH_STUDY_LINK}
          css={badgeStyles}
        >
          <StudyBadge />
        </a>
      </div>
    </Container>
  );
};

export default Header;
