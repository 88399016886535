import React from 'react';
import Container from './Container';
import { Row, Col } from 'emotion-flex';
import TripleTitle from '../common/TripleTitle';
import { ButtonLink } from '../common/Button';
import Text from '../Homepage/Text';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';

import { ReactComponent as ImgBloated } from '../../assets/images/problems/img-bloated.svg';
import { ReactComponent as ImgDigestion } from '../../assets/images/problems/img-digestion.svg';
import { ReactComponent as ImgLowEnergy } from '../../assets/images/problems/img-low_energy.svg';

function getImage(index: number) {
  if (index === 1) {
    return <ImgBloated />;
  } else if (index === 2) {
    return <ImgDigestion />;
  } else if (index === 3) {
    return <ImgLowEnergy />;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Problems({ title, items, text, callToAction }: any) {
  return (
    <Container
      css={{
        marginTop: '100px',
        marginBottom: '100px',
        [presets.lg]: { marginBottom: '30px' },
      }}
    >
      <Row>
        <Col sm={10} smOffset={1} md={6} mdOffset={3}>
          <TripleTitle headingText={title} paragraphText="" />
        </Col>
      </Row>

      <Row css={{ marginTop: '15px' }}>
        {items &&
          items.map(
            (
              item: {
                id: React.ReactText;
                image: number;
                text: React.ReactNode;
              },
              index: never
            ) => (
              <Col
                xs={12}
                md={4}
                key={item.id}
                css={{ textAlign: 'center', paddingTop: '15px' }}
              >
                {getImage(item.image)}
                <p
                  css={{
                    color: '#160B2C',
                    opacity: 0.6,
                    fontSize: '0.875rem',
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    [presets.lg]: { fontSize: '1rem' },
                  }}
                >
                  {item.text}
                </p>
              </Col>
            )
          )}
      </Row>

      <Row>
        <Col sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4}>
          <Text
            dangerouslySetInnerHTML={{ __html: text }}
            css={{ textAlign: 'center', paddingTop: '20px' }}
          />
        </Col>
      </Row>

      <Row>
        <Col css={{ textAlign: 'center', paddingTop: '15px' }}>
          <ButtonLink to={callToAction.url}>{callToAction.text}</ButtonLink>
        </Col>
      </Row>
    </Container>
  );
}
