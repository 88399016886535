import React from 'react';
import Effectiveness from '../Effectiveness';
import PersonalizedPrograms from '../PersonalizedPrograms';
import Problems from '../Problems';
import Tracking from '../Tracking';
import Reviews from './SlidingReviews';
import { ContentfulHomepage } from '../../../graphql-types';
import { NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK } from '../../../utils/constants';
import { useIntl } from 'react-intl';
import { TestimonialsSection } from '../../Doctors';
import Header from './Header';
import NextSteps from './NextSteps';
import CallToActionSection from './CallToActionSection';

interface HomepageProps {
  homepage: ContentfulHomepage;
  eliCheckerLink?: string;
  requestMaterialsFormId?: string;
  requestMaterialsFormPortalId?: string;
  bookAppointmentLink?: string;
  rezeptUrl?: string;
}

export const videoContainerStyle = {
  position: 'relative',
  paddingTop: '56.25%',
  marginBottom: '1rem',
};

export default function HomeView({
  homepage,
  eliCheckerLink,
  requestMaterialsFormId,
  requestMaterialsFormPortalId,
  bookAppointmentLink,
  rezeptUrl,
}: HomepageProps) {
  const intl = useIntl();

  return (
    <>
      <Header {...homepage.featureHeader} eliCheckerLink={eliCheckerLink} />
      <NextSteps
        rezeptUrl={rezeptUrl}
        requestMaterialsFormId={requestMaterialsFormId}
        requestMaterialsFormPortalId={requestMaterialsFormPortalId}
        bookAppointmentLink={bookAppointmentLink}
      />
      <Problems
        title={intl.formatMessage({
          id: 'homepage.problems.title',
          defaultMessage: 'Was sind mögliche Symptome eines Reizdarms?',
        })}
        items={[
          {
            text: intl.formatMessage({
              id: 'homepage.problems.item.one',
              defaultMessage:
                'Blähbauch, Bauchkrämpfe, Durchfall oder Verstopfung',
            }),
            image: 1,
            id: 1,
          },
          {
            text: intl.formatMessage({
              id: 'homepage.problems.item.two',
              defaultMessage: 'Unverträglichkeit verschiedener Lebensmittel',
            }),
            image: 2,
            id: 2,
          },
          {
            text: intl.formatMessage({
              id: 'homepage.problems.item.three',
              defaultMessage:
                'Wenig Energie und Abgeschlagenheit, innere Unruhe oder depressive Verstimmungen',
            }),
            image: 3,
            id: 3,
          },
        ]}
        text={intl.formatMessage({
          id: 'homepage.problems.text',
          defaultMessage:
            ' <b>Damit bist Du nicht allein.</b> In Deutschland leiden 16,6%\nan Reizdarmsyndrom, die meisten bekommen es aber nie diagnostiziert\noder behandelt. Finde jetzt heraus, ob Du für unsere ganzheitliche\nTherapie geeignet bist.',
        })}
        callToAction={{
          url: eliCheckerLink
            ? eliCheckerLink
            : NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK,
          text: intl.formatMessage({
            id: 'homepage.problems.cta.text',
            defaultMessage: 'Kostenfreie Therapie Starten',
          }),
        }}
      />

      <Tracking {...homepage.trackingSection} />
      <Effectiveness
        prefix={intl.formatMessage({
          id: 'homepage.effectiveness.prefix',
          defaultMessage: 'Ergebnisse',
        })}
        title={intl.formatMessage({
          id: 'homepage.effectiveness.title',
          defaultMessage: 'Unsere Wirksamkeit',
        })}
        text={intl.formatMessage({
          id: 'homepage.effectiveness.text',
          defaultMessage:
            'Erste Ergebnisse belegen die Wirksamkeit von Cara Care für Reizdarm',
        })}
        items={[
          {
            image: 1,
            id: 1,
            bigNumber: '67,8',
            smallNumber: '',
            showPercentage: true,
            text: intl.formatMessage({
              id: 'homepage.effectiveness.item.one',
              defaultMessage: 'schlossen die 12-wöchige Therapie ab.',
            }),
          },
          {
            image: 3,
            id: 3,
            bigNumber: '70,2',
            showPercentage: true,
            smallNumber: '',
            text: intl.formatMessage({
              id: 'homepage.effectiveness.item.three',
              defaultMessage:
                'zeigten eine signifikante, klinisch relevante Symptomreduktion.',
            }),
          },
          {
            image: 2,
            id: 2,
            bigNumber: '50,0',
            showPercentage: true,
            smallNumber: '',
            text: intl.formatMessage({
              id: 'homepage.effectiveness.item.two',
              defaultMessage:
                'zeigten eine klinisch relevante Verbesserung der Lebensqualität.',
            }),
          },
        ]}
        callToAction={{
          url: eliCheckerLink
            ? eliCheckerLink
            : NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK,
          text: intl.formatMessage({
            id: 'homepage.problems.cta.text',
            defaultMessage: 'Kostenfreie Therapie Starten',
          }),
        }}
      />
      <Reviews
        prefix={intl.formatMessage({
          id: 'homepage.reviews.prefix',
          defaultMessage: 'Bewertungen',
        })}
        title={intl.formatMessage({
          id: 'homepage.reviews.title',
          defaultMessage: 'Wir wissen zu begeistern',
        })}
        text={intl.formatMessage({
          id: 'homepage.reviews.text',
          defaultMessage: 'Das sagen unsere Nutzer über Cara Care für Reizdarm',
        })}
        items={[
          {
            text: intl.formatMessage({
              id: 'homepage.reviews.item.one',
              defaultMessage:
                '<b>Hilfreich, verständlich, kompetent</b> <br>Die App ist sehr einfach und verständlich aufgebaut. Man kann wirklich alles tracken, was die Magen-Darm-Gesundheit betrifft. Wirklich super!',
            }),
            author: 'Kimox',
            date: '',
            id: 1,
          },
          {
            text: intl.formatMessage({
              id: 'homepage.reviews.item.two',
              defaultMessage:
                '<b>Ich finds toll</b><br> Ich bin zu der App zurückgekehrt da ich wieder Probleme mit dem Magen habe. Hier finde ich immer wieder die Möglichkeit mein Problem zu finden und zu klären. Ich finde die App super und jetzt bin ich noch begeisterter da es Rezepte gibt! Super Idee werd mich durchprobieren. Danke für euer Produkt auch wenn eine ausführliche Dokumentation immer wieder anstrengend ist... aber hier ist alles einfach übersichtlich und am Ende war’s die Mühe wert',
            }),
            author: 'Eve Ababel',
            date: '',
            id: 2,
          },
          {
            text: intl.formatMessage({
              id: 'homepage.reviews.item.three',
              defaultMessage:
                '<b>Leicht verständlich und übersichtlich</b><br> Die Cara App hilft mir ein System in meinen Beschwerden zu erkennen. Sie ist sehr verständlich und übersichtlich aufgebaut und das tägliche Tracking der Lebensmittel/Beschwerden nimmt nur einige Minuten ein. Ich kann es jedem empfehlen, der auf der Suche nach dem Grund seiner Beschwerden ist und auf einem Weg zu einer besseren Gesundheit.',
            }),
            author: 'Deryarei',
            date: '',
            id: 3,
          },
        ]}
      />
      <PersonalizedPrograms {...homepage.personalizedPrograms} />
      <TestimonialsSection
        header={homepage.testimonialsHeader}
        testimonials={homepage.testimonialsList}
      />
      <CallToActionSection
        callToAction={{
          url: eliCheckerLink
            ? eliCheckerLink
            : NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK,
          text: intl.formatMessage({
            id: 'homepage.problems.cta.text',
            defaultMessage: 'Kostenfreie Therapie Starten',
          }),
        }}
      />
      <div className="hubspot-overlay">
        <div className="hubspot-overlay-close"></div>
        <div className="hubspot-overlay-content"></div>
      </div>
    </>
  );
}
